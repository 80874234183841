import React from "react";
import {Provider} from "react-redux";
import store from "./store/configureStore";
import ReactDOM from "react-dom";
import axios from "axios";
import AppRouter from "./routers/AppRouter";
import LoadingPage from "./components/common/LoadingPage";
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

// our scss
import './styles/styles.scss';

// optional alert configuration
const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 3000,
    offset: '30px',
    transition: transitions.FADE
}

// set up the base endpoint URL
axios.defaults.baseURL = '/';

const App = () => (
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...options}>
            <AppRouter />
        </AlertProvider>
    </Provider>
);

// show a loading page
ReactDOM.render(<LoadingPage />, document.getElementById("app"));

// render the application
ReactDOM.render(<App />, document.getElementById("app"));

