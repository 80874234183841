import React, { Component } from "react";
import {connect} from "react-redux";
import DmvLogo from "../../static/images_frontend/dmv_logo.png";
import {Link} from "react-router-dom";
import {LOGOUT_SUCCESS} from "../../actions/types";
import store from "../../store/configureStore";
import axios from "axios";
import {API, VERSION} from "../../routers/Urls";
import FeedbackModal from '../common/FeedbackModal';


export class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
        };
    };

    logout = () => {
        const token = this.props.auth.token;
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}
        axios.post(`${API}${VERSION}auth/logout`, null, config).then(r => {
        }).catch(e => {
            console.log('errors: ', e)
        });
        store.dispatch({type: LOGOUT_SUCCESS});
    }

    toggleModal = () => {
        this.setState({ modalShow: !this.state.modalShow });
    }

    render() {
      const {isAuthenticated, token} = this.props.auth;
      const guestLinks = (
          <header className="dmv__header">
              <div className="container">
                  <div className="row">
                      <div className="col-5 col-md-6">
                          <div className="dmv_logo">
                              <Link to='/'><img src={DmvLogo} alt="image"/></Link>
                          </div>
                      </div>
                      <div className="col-7 col-md-6">
                          <div className="dmv_button s_button text-end">
                              <a href="#" className="d-none"/>
                              <Link to='/login'>Sign in</Link>
                              <Link to='/register'>Join</Link>
                          </div>
                      </div>
                  </div>
              </div>
              <hr className="hr_h hr-block m-0"/>
          </header>
      );

      const authenticatedUserLinks = (
          <header className="dmv__header">
              <div className="container">
                  <div className="row">
                      <div className="col-5 col-md-6">
                          <div className="dmv_logo">
                              <Link to='/'><img src={DmvLogo} alt="image"/></Link>
                          </div>
                      </div>
                      <div className="col-7 col-md-6">
                          <div className="dmv_button s_button text-end">
                              <Link to='#' onClick={this.toggleModal}><span>Feedback</span></Link>
                              <Link to='/account'><span>My Account</span></Link>
                              <Link to='/login' onClick={this.logout}><span>Logout</span></Link>
                          </div>
                      </div>
                  </div>
              </div>
              <hr className="hr_h ht"/>
              <FeedbackModal show={this.state.modalShow} handleClose={this.toggleModal} token={token}
                             alert={this.props.alert} />
          </header>
      )

      if (isAuthenticated) {
          return authenticatedUserLinks;
      }  else {
          return guestLinks;
      }
    }
}


const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Header);
