import React, {Component} from 'react';
import {API, VERSION} from "../routers/Urls";
import axios from "axios";
import {Header} from "../components/layout/Header";
import {connect} from "react-redux";


export class AnswersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            questionsLoaded: false
        }
    };

    componentDidMount() {
        this.loadQuestions();
    }

    /**
     * Load the questions from the backend.
     */
    loadQuestions() {
        const url = window.location.pathname;
        const id = url.substring(url.lastIndexOf('/') + 1);
        axios.get(`${API}${VERSION}questions/${id}`).then(r => {
            this.setState({questionsLoaded: true, questions: r.data.questions});
        }).catch(e => {
            console.log(e);
        });
    }

    generateQuestionStructuredData = (questions) => ({
        "@context": "http://schema.org",
        "@type": "ItemList",
        "itemListElement": questions.map((question, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
                "@type": "Question",
                "name": question.title,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": question.options.find(option => option.isRight).text,
                    "image": question.options.find(option => option.isRight).imageUrl,
                },
                "suggestedAnswer": question.options.map((option) => ({
                    "@type": "Answer",
                    "text": option.text,
                    "image": option.imageUrl,
                }))
            }
        }))
    });


    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const questions = this.state.questions;
        const questionsLoaded = this.state.questionsLoaded;
        const auth = this.props.auth;
        const structuredData = questionsLoaded && questions ? this.generateQuestionStructuredData(questions) : null;
        return (
            <main className="main-area">
                <Header auth={auth}/>
                <div className="result__details">
                    <div className="container">
                        <div className="result_content">
                            <h2>Correct answers</h2>
                            <p>Here you can see the list of correct answers</p>
                        </div>
                        <div className="row">
                            {questionsLoaded &&
                                (questions.map((question, question_index) =>
                                <div className="col-lg-12 col-md-6">
                                    <div className="quistion_area">
                                        <h5>{question.title}</h5>
                                        <div className="row">
                                            {question.imageUrl && (
                                                <div className="building_im text-end">
                                                    <div>
                                                        <img className={'question_image'}
                                                             src={question.imageUrl} alt="image"/>
                                                    </div>
                                                </div>
                                            )}
                                            <div  className="col-lg-12">
                                                <div className="select_ans">
                                                    <ul>
                                                        {(question.options.map((option, option_index) =>
                                                            <li>
                                                                <input type="radio" id={question_index + option_index}
                                                                       name={question_index} checked={option.isRight} />
                                                                {option.imageUrl ?
                                                                    <label htmlFor={question_index + option_index} style={{margin: '-9px auto'}}>
                                                                        <img className={'option_image'} src={option.imageUrl} alt="image"/>
                                                                    </label>
                                                                    :
                                                                    <label htmlFor={question_index + option_index}>
                                                                        {option.text}
                                                                    </label>
                                                                }
                                                                <div className="check"/>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                </div>

                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}
            </main>
        );
    };
}


const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AnswersPage);
