import React from 'react'

export default () => {
    return (
        <div id="wrapper">
            <img src="https://i.imgur.com/qIufhof.png" alt="404 page"/>
            <div id="info">
                <h3>This page could not be found</h3>
            </div>
        </div>
    )
}
