import React, {Component} from 'react';
import profileSM from '../static/images_frontend/profile_sm.png';
import {Header} from "../components/layout/Header";
import {compose} from "redux";
import {connect} from "react-redux";
import {withAlert} from "react-alert";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import store from "../store/configureStore";
import {LOGOUT_SUCCESS} from "../actions/types";


export class AccountPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            profilePicture: '',
            isMember: false,
            loaded: false
        }
    };

    componentDidMount() {
        const token = this.props.auth.token;
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}
        axios.get(`${API}${VERSION}auth/user`, config).then(r => {
            const data = r.data
            const user = data.user;
            this.setState({firstName: user.firstName, lastName: user.lastName, email: user.email,
                isMember: user.isMember, loaded: true});
        }).catch(e => {
            store.dispatch({type: LOGOUT_SUCCESS});
            this.props.history.push('/login');
        });
    }

    /**
     * Handle email change.
     */
    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({email});
    }

    /**
     * Handle email change.
     */
    onChangeFirstName = (e) => {
        const firstName = e.target.value;
        this.setState({firstName});
    }

    /**
     * Handle email change.
     */
    onChangeLastName = (e) => {
        const lastName = e.target.value;
        this.setState({lastName});
    }

    /**
     * Handle email change.
     */
    onChangePassword = (e) => {
        const password = e.target.value;
        this.setState({password});
    }

    /**
     * Handle submitting the update user information.
     */
    onSubmit = (e) => {
        let payload = {};
        const {firstName, lastName, email, password} = this.state;

        if (firstName) {
            payload["firstName"] = firstName
        }

        if (lastName) {
            payload["lastName"] = lastName
        }

        if (email) {
            payload["email"] = email
        }

        if (password) {
            payload["password"] = password
        }

        const token = this.props.auth.token;
        const alert = this.props.alert;
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}
        axios.patch(`${API}${VERSION}auth/user/update`, payload, config).then(r => {
            const data = r.data
            const messages = data.messages[0];
            console.log(data)
            console.log(messages);
            alert.show(messages);
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });

    }

    /**
     * Handle profile picture update.
     */
    updateProfilePicture = () => {

    }

    /**
     * Handle remove the profile picture.
     */
    removeProfilePicture = () => {

    }


    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const auth = this.props.auth;
        const loaded = this.state.loaded;
        const {email, password} = this.state;
        return (
            <main className="main-area">
                <Header auth={auth} />
                {loaded && (
                    <div>
                        <div className="container">
                            <div className="account__box">
                                <div className="row">
                                    <div className="col-md-12 col-8">
                                        <div className="result_content">
                                            <h2>Account</h2>
                                            <p>Update and manage your account</p>
                                        </div>
                                    </div>
                                    {/*<div className="col-4">*/}
                                    {/*    <div className="d-none man_profile">*/}
                                    {/*        <img src={profileSM} alt="image"/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                <div className="clr_bg">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="edit__account">
                                                <p>Edit your account</p>
                                                <form action="#" method="POST" className="form">
                                                    {/*<div className="form-group focused">*/}
                                                    {/*    <label className="form-label" htmlFor="first">First Name</label>*/}
                                                    {/*    <input onChange={this.onChangeFirstName} id="first" value={firstName} className="form-input" type="text"/>*/}
                                                    {/*</div>*/}
                                                    {/*<div className="form-group focused">*/}
                                                    {/*    <label className="form-label" htmlFor="last_name">Last Name</label>*/}
                                                    {/*    <input onChange={this.onChangeLastName} id="last_name" value={lastName} className="form-input" type="text"/>*/}
                                                    {/*</div>*/}
                                                    <div className="form-group focused">
                                                        <label className="form-label" htmlFor="first">Email</label>
                                                        <input onChange={this.onChangeEmail} id="first" value={email} className="form-input" type="email" />
                                                    </div>
                                                    <div className="form-group focused">
                                                        <label className="form-label" htmlFor="password1">New password</label>
                                                        <input onChange={this.onChangePassword} id="password1" type="password" value={password} className="form-control form-input" name="password" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        {/*<div className="col-lg-4">*/}
                                        {/*    <div className="profile_im">*/}
                                        {/*        <img src={profileIM} alt="image"/>*/}
                                        {/*        <div className="upload_btn text-center">*/}
                                        {/*            <button>Upload new picture</button>*/}
                                        {/*            <br/>*/}
                                        {/*            <button>Remove picture</button>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>

                                    <div className="refound_profile">
                                        {/*<hr className="hr_h"/>*/}
                                        <div className="row clr_bg2">
                                            <div className="col-md-9 col-5">
                                                {/* Todo: Make sure to set this up when set up payments. */}
                                                {/*<a href="#" className="refund_a" data-bs-toggle="modal"*/}
                                                {/*   data-bs-target="#exampleModal">Refund me</a>*/}
                                            </div>
                                            <div className="col-md-3">
                                                <a onClick={this.onSubmit} className="save_a">Save Changes</a>
                                                {/*<a href="#" className="cancel_a">Cancel</a>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"*/}
                        {/*     aria-hidden="true">*/}
                        {/*    <div className="modal-dialog modal-dialog-centered">*/}
                        {/*        <div className="modal-content">*/}
                        {/*            <div className="modal-body">*/}
                        {/*                <div className="text-center popup_content">*/}
                        {/*                    <img src={exclamation} alt="image"/>*/}
                        {/*                    <p>Sorry that we have not deliver a good enough*/}
                        {/*                        <br/>*/}
                        {/*                        service to exist, please click exist. To get a refund,*/}
                        {/*                        <br/> please click refund*/}
                        {/*                    </p>*/}
                        {/*                    <button type="button" data-bs-dismiss="modal" aria-label="Close">*/}
                        {/*                        Exit*/}
                        {/*                    </button>*/}
                        {/*                    <a href="#" className="refund_a">Refund me</a>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                )}

            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(AccountPage);

