import React, {Component} from 'react';
import {Header} from "../components/layout/Header";
import {connect} from "react-redux";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";


export class ResultDetailPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            isDone:false,
            questionsLoaded: false
        }
    };

    componentDidMount() {
        this.loadIncorrectAnswers();
    }

    /**
     * Loading all the incorrect answers for a given attempt.
     */
    loadIncorrectAnswers = () => {
        const token = this.props.auth.token;
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}
        const attemptId = this.props.match.params.id;
        axios.get(`${API}${VERSION}attempts/detail/${attemptId}`, config).then(r => {
            const questions = r.data.questions;
            const isDone = r.data.isDone;
            this.setState({questionsLoaded: true, questions: questions, isDone:isDone});
        }).catch(e => {
            console.log(e);
        });
    }

    render() {
        const auth = this.props.auth;
        const questions = this.state.questions;
        const questionsLoaded = this.state.questionsLoaded;
        const isDone = this.state.isDone;
        return (
            <main className="main-area">
                <Header auth={auth} />
                <div className="result__details">
                    <div className="container">

                        <div className="result_content">
                            <h2>Result details</h2>
                            {questions.length === 0 && isDone && (
                                <span>Lucky you! You got all the questions right!</span>
                            )}
                            {questions.length !== 0 && isDone && (
                                <p>
                                    The options that are in red are your selected choices; however,
                                    the options that are in green are the correct answers.
                                </p>
                            )}
                            {!isDone && (
                                <p>
                                    Please complete the test to view the results.
                                </p>
                            )}
                        </div>

                        {questionsLoaded && isDone && (
                            <div className="row">
                                {(questions.map((question, index) =>
                                    <div className="col-lg-12 col-md-6">
                                        <div className="quistion_area" key={index}>
                                    <h5>{question.text}</h5>
                                    <div className="row">
                                        {question.imageUrl && (
                                            <div className="building_im text-end">
                                                <div>
                                                    <img className={'question_image'}
                                                         src={question.imageUrl} alt="image"/>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-12">
                                            <div className="select_ans">
                                                <ul>
                                                    {(question.options.map((option, index) => {
                                                        if (option.userChoice) {
                                                            return (
                                                                <li key={index}>
                                                                    <input type="radio" id={index} disabled={true} />
                                                                    {option.imageUrl ?
                                                                        <label htmlFor={option.id} style={{margin: '-9px auto'}}>
                                                                            <img className={'option_image'} src={option.imageUrl} alt="image"/>
                                                                        </label>
                                                                        :
                                                                        <label htmlFor={option.id}>
                                                                            {option.text}
                                                                        </label>
                                                                    }
                                                                    <div className="check ck_dander">
                                                                        <div className="inside"/>
                                                                    </div>
                                                                </li>
                                                            )
                                                        } else if (option.isRight) {
                                                            return (
                                                                <li key={index}>
                                                                    <input type="radio" id={index} disabled={true} checked={true}/>
                                                                    {option.imageUrl ?
                                                                        <label htmlFor={option.id} style={{margin: '-9px auto'}}>
                                                                            <img className={'option_image'} src={option.imageUrl} alt="image"/>
                                                                        </label>
                                                                        :
                                                                        <label htmlFor={option.id}>
                                                                            {option.text}
                                                                        </label>
                                                                    }
                                                                    <div className="check ck_active"/>
                                                                </li>
                                                            )
                                                        } else {
                                                            return (
                                                                <li key={index}>
                                                                    <input type="radio" id={index} disabled={true} />
                                                                    {option.imageUrl ?
                                                                        <label htmlFor={option.id} style={{margin: '-9px auto'}}>
                                                                            <img className={'option_image'} src={option.imageUrl} alt="image"/>
                                                                        </label>
                                                                        :
                                                                        <label htmlFor={option.id}>
                                                                            {option.text}
                                                                        </label>
                                                                    }
                                                                    <div className="check"/>
                                                                </li>
                                                            )
                                                        }
                                                    }))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ResultDetailPage);
