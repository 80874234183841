import React, {Component} from 'react';
import {Header} from "../components/layout/Header";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import {connect} from "react-redux";
import {compose} from "redux";
import {withAlert} from "react-alert";
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



export class PracticePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            questions: [],
            questionsLoaded: false,
            questionsTotalCount: null,
            remainingQuestionsCount: 0,
            testAttemptId:null,
            message: '',
            currentQuestion: null,
            currentQuestionId:null,
            currentOptionId:null,
            isDone:false,
            testEndedModel:false,
            grade:null,
            gradeLoaded:false
        }
    };

    componentDidMount() {
        this.loadQuestions();
    }

    /**
     * Load the test questions.
     */
    loadQuestions =  () => {
        const token = this.props.auth.token;
        const testId = this.props.match.params.id;
        const alert = this.props.alert;
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}
        axios.post(`${API}${VERSION}practice/${testId}`, null, config).then(r => {
            const data = r.data
            const questions = data.questions;
            const questionsTotalCount = data.questionsTotalCount;
            const testAttemptId = data.testAttemptId;
            const questionsLoaded = true;
            const currentQuestion = questions[0];
            const currentQuestionId = currentQuestion.id;
            const message = data.messages[0];
            const remainingQuestionsCount = data.questionsTotalCount - data.remainingQuestionsCount;
            this.setState({questions, questionsTotalCount, message, testAttemptId, questionsLoaded,
                currentQuestion, currentQuestionId, remainingQuestionsCount});
            alert.show(message);
        }).catch(e => {
            console.log('errors: ', e)
        });
    }

    /**
     * Submit an answer
     */
    onClickSubmitAnswer = () => {
        const currentOptionId = this.state.currentOptionId;
        const currentQuestionId = this.state.currentQuestionId;
        const testAttemptId = this.state.testAttemptId;

        const payload = {
            "optionId": currentOptionId,
            "questionId": currentQuestionId,
            "testAttemptId": testAttemptId
        }

        const token = this.props.auth.token;
        const alert = this.props.alert;
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}

        axios.post(`${API}${VERSION}answer`, payload, config).then(r => {
            const data = r.data;
            const isDone = data.isDone;
            const remainingQuestionsCount = this.state.remainingQuestionsCount + 1;

            // Display message that the questions has been answered.
            // alert.show(data.messages[0]);
            const questions = this.state.questions;
            const currentQuestions = [...questions];

            // Remove the question that has been answered.
            currentQuestions.splice(currentQuestions.findIndex(function(i) {return i.id === currentQuestionId}), 1);
            this.setState({remainingQuestionsCount, isDone, questions: currentQuestions});

            // Change the question or show pop up model with the grade.
            if (currentQuestions.length > 0) {
                this.onClickChangeQuestion();
            } else {
                const grade = data.grade;
                this.setState({grade, testEndedModel: true, gradeLoaded:true})
            }

        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    /**
     * Change the question by grabbing a random question from the list.
     */
    onClickChangeQuestion = () => {
        const questions = this.state.questions;
        const questionLen = questions.length;
        const randomIndex = Math.floor(Math.random() * questionLen);
        const currentQuestion = questions[randomIndex];
        const currentQuestionId = currentQuestion.id;
        this.setState({currentQuestion, currentQuestionId, currentOptionId: null});

        // Reset the options
        const elements = document.getElementsByTagName("input");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].type === "radio") {
                elements[i].checked = false;
            }
        }
    }

    /**
     * Set the option
     */
    onClickOption = (e) => {
        const optionId = e.target.value;
        this.setState({currentOptionId: optionId});
    }

    onClickModel = (e) => {
        const testEndedModel = this.state.testEndedModel;
        this.setState({testEndedModel: !testEndedModel});
    }

    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const currentQuestion = this.state.currentQuestion;
        const questionsLoaded = this.state.questionsLoaded;
        const questionsTotalCount = this.state.questionsTotalCount;
        const remainingQuestionsCount = this.state.remainingQuestionsCount;
        const testEndedModel = this.state.testEndedModel;
        const auth = this.props.auth;
        const grade = this.state.grade;
        const gradeLoaded = this.state.gradeLoaded;
        const testId = this.props.match.params.id;
        return (
            <main className="main-area">
                <Header auth={auth} />
                {questionsLoaded && (
                    <div className="result__details">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="result_content">
                                        <h2>Practice questions</h2>
                                        <p>Completed {remainingQuestionsCount} out of {questionsTotalCount}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="exit_btn btn_1st text-end">
                                        <Link to='/'>Exit</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="quistion_area">
                                        <h5>{currentQuestion.title}</h5>
                                        <div className="row">
                                            {currentQuestion.imageUrl && (
                                                <div className="building_im text-end">
                                                    <div>
                                                        <img className={'question_image'}
                                                             src={currentQuestion.imageUrl} alt="image"/>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-12">
                                                <div className="select_ans">
                                                    <ul>
                                                        {currentQuestion.options &&
                                                        (currentQuestion.options.map((option, optionIndex) =>
                                                            <li key={optionIndex}>
                                                                <input type="radio" value={option.id} id={optionIndex}
                                                                       name={currentQuestion.id}
                                                                       onClick={this.onClickOption} />
                                                                {option.imageUrl ?
                                                                    <label htmlFor={optionIndex} style={{margin: '-9px auto'}}>
                                                                        <img className={'option_image'} src={option.imageUrl} alt="image"/>
                                                                    </label>
                                                                    :
                                                                    <label htmlFor={optionIndex}>
                                                                        {option.text}
                                                                    </label>
                                                                }
                                                                <div className="check"/>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='col-6'>
                                                <div className="quis_change">
                                                    <a onClick={this.onClickChangeQuestion}>Change question</a>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className="text-end submit__a">
                                                    <a onClick={this.onClickSubmitAnswer}>Submit</a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="exit_btn d-none">
                                <Link to='/'>Exit</Link>
                            </div>
                        </div>
                    </div>
                )}

                <Modal
                    show={testEndedModel}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Congrats! You have completed this quiz!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Good job! You answered {gradeLoaded &&  grade} questions out of {questionsTotalCount} correctly!
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button style={{background: '#613eb7', borderRadius: '8px'}} onClick={(e) => location.reload()}>Retake</Button>
                        <Link to={`/results/${testId}`}><Button style={{background: '#613eb7', borderRadius: '8px'}}>Results</Button></Link>
                        <Link style={{marginLeft: 'auto'}} to='/'><Button style={{background: '#613eb7', borderRadius: '8px'}}>Home</Button></Link>
                    </Modal.Footer>
                </Modal>
            </main>
        );
    };
}



const mapStateToProps = state => ({
    auth: state.auth
});


export default compose(connect(mapStateToProps), withAlert())(PracticePage);
