import React, {Component} from 'react';
import SignLogo from '../static/images_frontend/sign_logo.png';
import GoogleLogo from '../static/images_frontend/ic_google_logo.png';
import {Header} from "../components/layout/Header";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import {LOGIN_SUCCESS} from "../actions/types";
import {compose} from "redux";
import {connect} from "react-redux";
import {withAlert} from "react-alert";
import {Link} from "react-router-dom";


export class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
    };

    componentDidMount() {
        const emailAddress = document.getElementById("email-address-group");
        emailAddress.addEventListener("focusin", (event) => {
            emailAddress.classList.add("focused");
        });

        const pass = document.getElementById("password-group");
        pass.addEventListener("focusin", (event) => {
            pass.classList.add("focused");
        });
    }

    /**
     *
     */
    onClickLogin = (e) => {
        e.preventDefault();

        const password = this.state.password;
        const email = this.state.email;
        const googleSignUp = false;
        const alert = this.props.alert;
        const payload = {password: password, username: email, googleSignUp: googleSignUp}
        const config = {headers: {'Content-Type':'application/json'}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/login`, body, config).then(r => {
            const data = r.data
            alert.show(data.messages[0]);
            this.props.dispatch({type: LOGIN_SUCCESS, payload:data})
            this.props.history.push('/');
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    onChangePassword = (e) => {
        const password = e.target.value;
        this.setState({password});
    }

    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({email});
    }


    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const email = this.state.email;
        const password = this.state.password;
        const auth = this.props.auth;
        return (
            <main className="main-area dmt_test">
                <Header auth={auth} />
                <div className="around__area">
                    <div className="container">
                        <div className="row m-0">
                            <div className="col-lg-6 col-md-6" style={{background: "#F9F9F9"}}>
                                <div className="around_im">
                                    <img src={SignLogo} alt="image"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6"  style={{background: "#fff"}}>
                                <div className="aside__part">
                                    <div className="sign_form">
                                        <h2>Sign in</h2>
                                        <p className="new_p">New user?
                                            <Link to='/register'>Create an account</Link>
                                        </p>
                                        <div className="form-wrapper">
                                            <form action="#" method="POST" className="form">
                                                <div className="form-group focused" id='email-address-group'>
                                                    <label className="form-label" htmlFor="first">Email address</label>
                                                    <input id="first" className="form-input" type="email" required="" value={email}
                                                            onChange={this.onChangeEmail}/>
                                                </div>
                                                <div className="form-group focused" id='password-group'>
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                    <input id="password" type="password" className="form-control form-input"
                                                           name="password" value={password} onChange={this.onChangePassword}/>
                                                </div>
                                                <div className="forget_a text-end">
                                                    <p className="m-0 new_p">
                                                        {/*<a href="/reset">Forgot password?</a>*/}
                                                        <Link to='/reset-password'>Forgot password?</Link>
                                                    </p>
                                                </div>
                                                <button type="submit" onClick={this.onClickLogin}>Sign In</button>
                                                {/*<div className="comment-form-cookies-consent d-flex mt-1 mt-md-4">*/}
                                                {/*    <div className="ck_int">*/}
                                                {/*        <input id="wp-comment-cookies-consent"*/}
                                                {/*               name="wp-comment-cookies-consent" type="checkbox"*/}
                                                {/*               value="yes"/>*/}
                                                {/*    </div>*/}
                                                {/*    <label htmlFor="wp-comment-cookies-consent" className="check_bx">*/}
                                                {/*        By clicking Create account, I agree that I have read and accepted*/}
                                                {/*        the <span>Terms of Use</span> and <span>Privacy Policy.</span>*/}
                                                {/*    </label>*/}
                                                {/*</div>*/}
                                            </form>

                                            {/*<div className="anything">*/}
                                            {/*    <hr className="hz"/>*/}
                                            {/*    <p>or</p>*/}
                                            {/*</div>*/}

                                            {/* Todo: I might implement the google button later */}
                                            {/*<button className="google_btn">*/}
                                            {/*    <img src={GoogleLogo} alt="image"/>*/}
                                            {/*    Sign in with Google*/}
                                            {/*</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(LoginPage);
