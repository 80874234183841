import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import PracticePage from "../pages/PracticePage";
import LoginPage from "../pages/LoginPage";
import Join from "../pages/JoinPage";
import RegisterPage from "../pages/RegisterPage";
import ResultListPage from "../pages/ResultListPage";
import ResultDetailPage from "../pages/ResultDetailPage";
import AccountPage from "../pages/AccountPage";
import AnswersPage from "../pages/AnswersPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import PageNotFound from "../pages/404Page";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import UserStats from "../pages/StatsPage";

const AppRouter = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={LandingPage} />
            <PublicRoute exact path="/stats" component={UserStats} />
            <PublicRoute exact path="/reset-password" component={ResetPasswordPage} />
            <PublicRoute exact path="/login" component={LoginPage} />
            <PublicRoute exact path="/register" component={RegisterPage} />
            <PrivateRoute exact path="/practice/:id" component={PracticePage} />
            <PrivateRoute exact path="/join" component={Join} />
            <PrivateRoute exact path="/results/:id" component={ResultListPage} />
            <PrivateRoute exact path="/attempts/:id" component={ResultDetailPage} />
            <PrivateRoute exact path="/account" component={AccountPage} />
            <PrivateRoute exact path="/answers/:id" component={AnswersPage} />
            <PublicRoute component={PageNotFound} />
        </Switch>
    </BrowserRouter>
);


export default AppRouter;
