import React, {Component} from 'react';
import logo1 from '../static/images_frontend/logo1.png';
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Header} from "../components/layout/Header";
import store from "../store/configureStore";
import {LOGOUT_SUCCESS} from "../actions/types";
import {withAlert} from "react-alert";
import {compose} from "redux";


export class LandingPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tests: [],
            testsLoaded: false
        }
    };

    /**
     * Load the component.
     */
    componentDidMount() {
        this.loadTests();
    }

    /**
     * Load all tests.
     */
    loadTests() {
        const isAuthenticated = this.props.auth.isAuthenticated;
        if (isAuthenticated) {
            const token = this.props.auth.token;
            const config = {headers: {'Content-Type':'application/json'}};
            if (token) {config.headers['Authorization'] = `Token ${token}`}
            axios.get(`${API}${VERSION}tests`, config).then(r => {
                this.setState({testsLoaded: true, tests: r.data});
            }).catch(e => {
                axios.post(`${API}${VERSION}auth/logout`, null, config).then(r => {
                }).catch(e => {
                    console.log('errors: ', e)
                });
                store.dispatch({type: LOGOUT_SUCCESS});
                this.props.history.push('/login');
            });
        } else {
            axios.get(`${API}${VERSION}tests`).then(r => {
                this.setState({testsLoaded: true, tests: r.data});
            }).catch(e => {
                console.log(e);
            });
        }
    }

    generateStructuredData = (quizzes) => ({
        "@context": "http://schema.org",
        "@type": "ItemList",
        "itemListElement": quizzes.map((quiz, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "item": {
                "@type": "EducationalOccupationalProgram",
                "name": quiz.title,
                "description": `Description for ${quiz.title}`,
                "url": `https://www.dmvprep.net/quiz/${quiz.id}`,
                "offers": {
                    "@type": "Offer",
                    "availability": "http://schema.org/InStock"
                }
            }
        }))
    });

    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const tests = this.state.tests;
        const testsLoaded = this.state.testsLoaded;
        const auth = this.props.auth;
        const isAuthenticated = auth.isAuthenticated;
        const alert = this.props.alert;
        const structuredData = testsLoaded && tests ? this.generateStructuredData(tests) : null;
        return (
            <main className="main-area">
                <Header auth={auth} alert={alert} />
                <section className="bg-area">
                    <div className="container">
                        <div className="bac__bg">
                            <h2>Prepare for the
                                <br className="d-none"/> written driving test <br className="d-none"/> in no time.
                            </h2>
                            <p>Practice more than 200+ questions for free.</p>
                            <img src={logo1} alt="image"/>
                        </div>
                        {testsLoaded &&
                            (tests.map((object, index) =>
                                <div className="quiz__area">
                                    <div className="row">
                                        <div className="col-lg-2 col-md-3">
                                            <div className="quiz_heading">
                                                <h2>Quiz <span>0{index + 1}</span></h2>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 ps-md-0 ps-auto">
                                            <div className="quiz_p">
                                                <p>{object.title}</p>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-5">
                                            <div className="quiz_link result-btns">
                                                {/* Results Button */}
                                                {isAuthenticated ? (
                                                    <Link to={`/results/${object.id}?testNumber=${index + 1}`}>
                                                        Results
                                                    </Link>
                                                ) : (
                                                    <Link to={`/login`}>
                                                        Results
                                                    </Link>
                                                )}

                                                {/* View answers Button */}
                                                <Link to={`/answers/${object.id}`}>View answers</Link>

                                                {/* Practice */}
                                                {isAuthenticated ? (
                                                    <Link to={`/practice/${object.id}`}>
                                                        {object.pendingTest ? <>Resume</> : <>Practice</>}
                                                    </Link>
                                                ) : (
                                                    <Link to={`/login`}>Practice</Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                         aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body p-0">
                                    <div className="text-center popup_content2">
                                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"><i className="fa-thin fa-xmark"/></button>
                                        <div id="full-stars-example">
                                            <h4 style={{fontSize: '16px', fontWeight: '600px', marginBottom: '30px'}}>Tell us
                                                what you like most about this service</h4>
                                            <div className="rating-group">

                                                <input className="rating__input rating__input--none" name="rating3" id="rating-none" value="0" type="radio"/>
                                                    <label aria-label="1 star" className="rating__label"
                                                           htmlFor="rating-1"><i className="rating__icon rating__icon--star fa fa-star"/></label>
                                                    <input className="rating__input" name="rating3" id="rating-1"
                                                       value="1" type="radio"/>
                                                    <label aria-label="2 stars" className="rating__label"
                                                           htmlFor="rating-2"><i className="rating__icon rating__icon--star fa fa-star"/></label>
                                                    <input className="rating__input" name="rating3" id="rating-2"
                                                           value="2" type="radio"/>
                                                    <label aria-label="3 stars" className="rating__label"
                                                           htmlFor="rating-3"><i className="rating__icon rating__icon--star fa fa-star"/></label>
                                                    <input className="rating__input" name="rating3"
                                                           id="rating-3" value="3" type="radio" checked />
                                                    <label aria-label="4 stars" className="rating__label"
                                                           htmlFor="rating-4"><i className="rating__icon rating__icon--star fa fa-star"/></label>
                                                    <input className="rating__input" name="rating3"
                                                           id="rating-4" value="4" type="radio"/>
                                                    <label aria-label="5 stars" className="rating__label" htmlFor="rating-5">
                                                        <i className="rating__icon rating__icon--star fa fa-star"/>
                                                    </label>
                                                    <input className="rating__input" name="rating3" id="rating-5" value="5" type="radio"/>
                                            </div>
                                        </div>

                                        <form action="#" method="POST">
                                            <input type="text" name="#" placeholder="Write Review..." required=""/>
                                            <input type="submit" name="#" value="Publish"/>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {structuredData && (
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                )}

            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(LandingPage);
