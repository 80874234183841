import React, {Component} from 'react';

export class Attempt extends Component {

    constructor(props) {
        super(props);
    };


    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const trophy = this.props.trophy;
        const index = this.props.index;
        const fullGrade = this.props.fullGrade;
        const attempt = this.props.attempt;
        const createdAt = attempt.createdAt;
        const grade = attempt.grade;
        let finalGrade;
        if (grade !== 0) {finalGrade = Math.ceil((grade / fullGrade) * 100);} else {finalGrade = 0}
        return (
            <div className="quiz__area" key={index}>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-9">
                        <div className="quiz_heading">
                            <h2>Quiz <span>01:{index + 1}</span></h2>
                            <p className="date_p mt-1">Date: {createdAt}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-12 order-2 order-md-0 ps-md-0 ps-auto">
                        <div className="lear_2">
                            <div className="progress">
                                <div className="progress-done" style={{width: finalGrade + '%'}} data-done={finalGrade}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-md-2 col-3 order-1 order-md-0">
                        <div className="trophy_area text-center">
                            <img src={trophy} alt={""}/>
                            <p className="date_p mt-2">{finalGrade}/100</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

