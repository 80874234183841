import React, {Component} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import {compose} from "redux";
import {REGISTER_SUCCESS} from "../actions/types";
import {Header} from "../components/layout/Header";
import SignLogo from "../static/images_frontend/sign_logo.png";


export class RegisterPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            firstName: '',
            lastName: '',
            errors: '',
            email: ''
        }
    };

    componentDidMount() {

        const emailAddress = document.getElementById("email-group");
        emailAddress.addEventListener("focusin", (event) => {
            emailAddress.classList.add("focused");
        });
        // const firstName = document.getElementById("first-name-group");
        // firstName.addEventListener("focusin", (event) => {
        //     firstName.classList.add("focused");
        // });
        // const lastName = document.getElementById("last-name-group");
        // lastName.addEventListener("focusin", (event) => {
        //     lastName.classList.add("focused");
        // });
        const password = document.getElementById("password-group");
        password.addEventListener("focusin", (event) => {
            password.classList.add("focused");
        });

    }

    /**
     * Create a new user.
     */
    onSubmit = (e) => {
        e.preventDefault();

        const password = this.state.password;
        // const firstName = this.state.firstName;
        // const lastName = this.state.lastName;
        const email = this.state.email;
        const googleSignUp = false;
        const alert = this.props.alert;

        const payload = {
            password: password,
            // firstName: firstName,
            // lastName: lastName,
            email: email,
            googleSignUp: googleSignUp,
        }

        const config = {headers: {'Content-Type':'application/json'}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/register`, body, config).then(r => {
            const data = r.data
            alert.show(data.messages[0]);
            this.props.dispatch({type: REGISTER_SUCCESS, payload:data})
            this.props.history.push('/');
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });

    }

    /**
     * Create a new user account via the their google account.
     */
    onSuccessGoogleAccount = () => {
    }


    onFailureGoogleAccount = () => {

    }

    onChangePassword = (e) => {
        const password = e.target.value;
        this.setState({password});
    }

    onChangeFirstName = (e) => {
        const firstName = e.target.value;
        this.setState({firstName});
    }

    onChangeLastName = (e) => {
        const lastName = e.target.value;
        this.setState({lastName});
    }

    onChangeEmail = (e) => {
        const email = e.target.value;
        this.setState({email});
    }

    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const password = this.state.password;
        const auth = this.props.auth;
        return (
            <main className="main-area dmt_test">
                <Header auth={auth} />
                <div className="around__area">
                    <div className="container">
                        <div className="row m-0">
                            <div className="col-lg-6 col-md-6" style={{background: "#F9F9F9"}}>
                                {/* Todo: add to line 112 when adding reviews order-2 order-md-0 */}
                                {/*<div className="left__side">*/}
                                    {/* Todo: Return the views later in the future. */}
                                    {/*<h2>Reviews</h2>*/}
                                    {/*<Review />*/}
                                    {/*<Review />*/}
                                    {/*<Review />*/}
                                    <div className="around_im">
                                        <img src={SignLogo} alt="image"/>
                                    </div>
                                {/*</div>*/}
                            </div>

                            <div className="col-lg-6 col-md-6 order-1 order-md-0 bac_f9" style={{background: "#fff"}}>
                                <div className="aside__part sm_part">
                                    <div className="sign_form">
                                        <h2>Create an account</h2>
                                        <p className="new_p">Already have an account?<Link to="/login">Sign in</Link></p>
                                        <div className="form-wrapper">
                                            <form method="POST" className="form">
                                                <div className="form-group focused" id={'email-group'}>
                                                    <label className="form-label" htmlFor="first">Email address</label>
                                                    <input id="first" className="form-input" type="email" required=""
                                                           onChange={this.onChangeEmail}/>
                                                </div>
                                                {/*<div className="d-flex">*/}
                                                {/*    <div className="form-group fr_group me-3 focused" id={'first-name-group'}>*/}
                                                {/*        <label className="form-label" htmlFor="second">*/}
                                                {/*            First name*/}
                                                {/*        </label>*/}
                                                {/*        <input id="second" className="form-input" type="text"*/}
                                                {/*               required="" onChange={this.onChangeFirstName}/>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="form-group fr_group focused" id={'last-name-group'}>*/}
                                                {/*        <label className="form-label" htmlFor="third">Last name</label>*/}
                                                {/*        <input id="third" className="form-input" type="text"*/}
                                                {/*               required="" onChange={this.onChangeLastName}/>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="form-group focused" id={'password-group'}>
                                                    <label className="form-label" htmlFor="password">Password</label>
                                                    <input id="password" type="password" className="form-control form-input"
                                                           name="password" value={password} onChange={this.onChangePassword} />
                                                </div>

                                                <button type="submit" onClick={this.onSubmit}>Create account</button>

                                                {/* Todo: In case you would like to add agreements terms, then you could do it here.*/}
                                                {/*<div className="comment-form-cookies-consent d-flex mt-1 mt-md-4">*/}
                                                {/*    <div className="ck_int">*/}
                                                {/*        <input id="wp-comment-cookies-consent"*/}
                                                {/*               name="wp-comment-cookies-consent" type="checkbox"*/}
                                                {/*               value="yes"/>*/}
                                                {/*    </div>*/}
                                                {/*    <label htmlFor="wp-comment-cookies-consent" className="check_bx">*/}
                                                {/*        By clicking Create account, I agree that I have read and accepted*/}
                                                {/*        the <span>Terms of Use</span> and <span>Privacy Policy.</span>*/}
                                                {/*    </label>*/}
                                                {/*</div>*/}
                                            </form>

                                            {/*<div className="anything">*/}
                                            {/*    <hr className="hz"/>*/}
                                            {/*        <p>or</p>*/}
                                            {/*</div>*/}

                                            {/*/!* Todo: Get back to the button and make it work. *!/*/}
                                            {/*<GoogleLogin*/}
                                            {/*    onSuccess={this.onSuccessGoogleAccount}*/}
                                            {/*    onError={this.onFailureGoogleAccount}*/}
                                            {/*/>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(RegisterPage);
