import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from "axios";
import {API, VERSION} from "../../routers/Urls";

function FeedbackModal({ show, handleClose, token, alert }) {
    const [feedback, setFeedback] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {"feedback": feedback}
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}
        axios.post(`${API}${VERSION}feedback`, payload, config).then(r => {
            setFeedback('');
            alert.success("Feedback submitted successfully.")
        }).catch(e => {
            alert.error("No feedback provided.");
        });

        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Share Your Thoughts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Control as="textarea" rows={5} value={feedback} onChange={(e) => setFeedback(e.target.value)} />
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-2">
                        <Button variant="primary" type="submit" style={{ backgroundColor: '#6f42c1', borderColor: '#643ab0' }}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default FeedbackModal;
