import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
} from "../actions/types";

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    isLoading: false,
    user: null,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
          ...state,
          token: action.payload.token,
          user: action.payload.user,
          isAuthenticated: true,
          isLoading: false,
        };

        case LOGOUT_SUCCESS:
        case LOGIN_FAIL:
        case REGISTER_FAIL:
            localStorage.removeItem('token'); return {
          ...state,
          isAuthenticated: false,
          isLoading: false,
          token: null,
          user:null
        };
        default: return state;
    }
}






