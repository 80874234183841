import React, {Component} from 'react';
import DmvLogo from '../static/images_frontend/dmv_logo.png';
import notification from '../static/images_frontend/notification.png';
import avatar from '../static/images_frontend/avatar.png';
import arrowDroDown from '../static/images_frontend/arrow_drop_down.png';


export default class RegisterPage extends Component {

    constructor(props) {
        super(props);
    };


    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        return (
            <main className="main-area">
                <header className="dmv__header">
                    <div className="container">
                        <div className="row">
                            <div className="col-5 col-md-6">
                                <div className="dmv_logo">
                                    <a href="#"><img src={DmvLogo} alt="image"/></a>
                                </div>
                            </div>
                            <div className="col-7 col-md-6 item_flex">
                                <div className="notification_a text-end">
                                    <a href="#"><img src={notification} alt="image"/></a>
                                    <a href="#" className="se_arw text-decoration-none">
                                        <img src={avatar} alt="image"/><span>Maxbert</span>
                                            <img className="arw" src={arrowDroDown} alt="image"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                <hr className="hr_h ht"/>
                </header>

                <div className="main__center">
                    <div className="center__area">
                        <div className="join_quiz">
                            <h2>Join Quiz</h2>
                            <p>Access more than 250+ questions for just $9.99</p>
                            <form action="#" method="POST" className="frm">
                                <label>
                                    <span>Email Address</span>
                                    <input className="form-control" type="email" name="#" required=""/>
                                </label>
                                <div className="form-group">
                                    <label htmlFor="password-field"
                                           className="control-label"><span>Password</span></label>
                                    <input id="password-field" type="password" className="form-control" name="password"
                                           value=""/>
                                    <span toggle="#password-field"
                                          className="fa fa-fw fa-eye field-icon toggle1-password"/>
                                </div>

                                <div className="d-flex">
                                    <div className="tel_item me-3">
                                        <label>
                                            <span>Card Number</span>
                                            <input className="form-control" type="tel" name="#"/>
                                        </label>
                                    </div>
                                    <div className="tel_item">
                                        <label>
                                            <span>Expiration Date</span>
                                            <input className="form-control" type="date" name="#"/>
                                        </label>
                                    </div>
                                </div>
                                <div className="text-end">
                                    <button type="submit">Join</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        );
    };
};
