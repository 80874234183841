
import React from "react";
import loadingImage from '../../static/images_frontend/loader.gif'

const LoadingPage = () => (
    <div className={"loader"} id={"loading-img"}>
        <img alt={"Loading"} className={"loader__img"} src={loadingImage} />
    </div>
);

export default LoadingPage;
