import React, { Component } from 'react';
import axios from 'axios';
import {API, VERSION} from "../routers/Urls";

class UserStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalUsers: 0,
            activeUsers: 0,
            loading: true,
        };
    }

    componentDidMount() {
        // Make an Axios GET request to the API endpoint
        axios.get(`${API}${VERSION}stats`)
            .then(response => {
                const { totalUsers, activeUsers } = response.data;
                this.setState({ totalUsers, activeUsers, loading: false });
            })
            .catch(error => {
                console.error('Error fetching statistics:', error);
                this.setState({ loading: false });
            });
    }

    render() {
        const { totalUsers, activeUsers, loading } = this.state;

        return (
            <div>
                <h1>User Statistics</h1>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        <p>Total Users: {totalUsers}</p>
                        <p>Active Users: {activeUsers}</p>
                    </>
                )}
            </div>
        );
    }
}

export default UserStats;
