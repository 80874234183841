import React, {Component} from 'react';
import trophy from '../static/images_frontend/trophy-icon.png';
import {Header} from "../components/layout/Header";
import {connect} from "react-redux";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";
import {Attempt} from "../components/common/Attempt";
import {Link} from "react-router-dom";


export class ResultListPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attemptList: [],
            attemptListLoaded: false,
            fullGrade: 0
        }
    };

    componentDidMount() {
        this.loadAttempts();
    }

    /**
     * Load all attempts
     */
    loadAttempts() {
        const testId = this.props.match.params.id;
        const token = this.props.auth.token;
        const config = {headers: {'Content-Type':'application/json'}};
        if (token) {config.headers['Authorization'] = `Token ${token}`}
        axios.get(`${API}${VERSION}attempts/${testId}`, config).then(r => {
            const fullGrade = r.data.fullGrade;
            const attemptList = r.data.testAttempts;
            this.setState({attemptListLoaded: true, fullGrade: fullGrade, attemptList: attemptList});
        }).catch(e => {
            console.log(e);
        });
    }

    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const auth = this.props.auth;
        const attemptListLoaded = this.state.attemptListLoaded;
        const fullGrade = this.state.fullGrade;
        const attemptList = this.state.attemptList;
        const fullPath = window.location.href;
        const params = new URL(fullPath).searchParams;
        const testNumber = params.get('testNumber');
        return (
            <main className="main-area">
                <Header auth={auth} />
                {attemptListLoaded && (
                    <section className="bg-area">
                        <div className="container">
                            <div className="result_content attempt">
                                <h2 className="m-0">Results</h2>
                                <p className="mt-3 mb-4">0:{testNumber} Quiz - Attempts {attemptListLoaded ? attemptList.length : ''}</p>
                            </div>
                            {attemptList.length !== 0 ? attemptList.map((attempt, index) => (
                                <Link to={`/attempts/${attempt.id}`} style={{textDecoration:"none"}}>
                                    <Attempt fullGrade={fullGrade} attempt={attempt} trophy={trophy} index={index}/>
                                </Link>
                            )) : ''}
                        </div>
                    </section>
                )}
            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(ResultListPage);
