import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

// Load the initial state from local storage (if available)
const initialState = loadStateFromLocalStorage();

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
);

// Subscribe to store changes and save the state to local storage
store.subscribe(() => {
    saveStateToLocalStorage(store.getState());
});

export default store;

// Function to save the state to local storage
function saveStateToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("GlobalState", serializedState);
    } catch (err) {
        console.error("Error saving state to local storage:", err);
    }
}

// Function to load the state from local storage
function loadStateFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem("GlobalState");
        if (serializedState === null) {
            return undefined; // Return undefined to use the initialState
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.error("Error loading state from local storage:", err);
        return undefined; // Return undefined to use the initialState
    }
}
