import React, {Component} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withAlert} from "react-alert";
import {Header} from "../components/layout/Header";
import profileSM from "../static/images_frontend/profile_sm.png";
import axios from "axios";
import {API, VERSION} from "../routers/Urls";


export class ResetPasswordPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step1: true,
            step2: false,
            step3: false,
            email: '',
            verificationNumber: '',
            newPassword: ''
        }
    };

    /**
     * Create or generate a new verification number.
     * @param e
     */
    onClickSend = (e) => {
        e.preventDefault();
        const email = this.state.email;
        const alert = this.props.alert;
        const payload = {email: email}
        const config = {headers: {'Content-Type':'application/json'}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/create-verification-number`, body, config).then(r => {
            const data = r.data;
            alert.show(data.messages[0]);
            this.setState({step1: false, step2: true});
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    /**
     * Handles verifying if the owner if the email is indeed the owner.
     * @param e
     */
    onClickVerify = (e) => {
        e.preventDefault();
        const email = this.state.email;
        const verificationNumber = this.state.verificationNumber;
        const alert = this.props.alert;
        const payload = {verificationNumber: verificationNumber, email: email}
        const config = {headers: {'Content-Type':'application/json'}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/verify-email`, body, config).then(r => {
            const data = r.data;
            alert.show(data.messages[0]);
            this.setState({step2: false, step3: true});
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    /**
     * Handles saving a new password.
     * @param e
     */
    onClickReset = (e) => {
        e.preventDefault();
        const newPassword = this.state.newPassword;
        const email = this.state.email;
        const alert = this.props.alert;
        const payload = {newPassword: newPassword, email: email}
        const config = {headers: {'Content-Type':'application/json'}};
        const body = JSON.stringify(payload);
        axios.post(`${API}${VERSION}auth/reset`, body, config).then(r => {
            const data = r.data;
            alert.show(data.messages[0]);
            this.props.history.push('/login');
        }).catch(e => {
            const errors = e.response.data.errors;
            alert.error(errors[0]);
        });
    }

    /**
     * Handle changes the email state var.
     * @param e
     */
    onChangeEmail= (e) => {
        const email = e.target.value;
        this.setState({email});
    }

    /**
     * Handle changes to the verification number state var.
     * @param e
     */
    onChangeVerificationNumber = (e) => {
        const verificationNumber = e.target.value;
        this.setState({verificationNumber});
    }

    /**
     * Handle changes to the new password var.
     * @param e
     */
    onChangeNewPassword = (e) => {
        const newPassword = e.target.value;
        this.setState({newPassword});
    }

    /**
     * Render the dom
     * @returns {*}
     */
    render() {
        const {step1, step2, step3, email, verificationNumber, newPassword} = this.state;
        const auth = this.props.auth;
        return (
            <main className="main-area">
                <Header auth={auth} />
                <div>
                    <div className="container">
                        <div className="pass__box">
                            <div className="row">
                                <div className="col-12">
                                    <div className="result_content">
                                        <h2>Reset Your Password</h2>
                                    </div>
                                </div>
                            </div>

                            {step1 && (
                                <div className="clr_bg">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="edit__account">
                                                <p>Please add the email that you used to create an account with us</p>
                                                <div className="form-group focused">
                                                    <label className="form-label" htmlFor="email">Email</label>
                                                    <input onChange={this.onChangeEmail} id="email" value={email}
                                                           className="form-input" type="text"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="refound_profile">
                                        <div className="row clr_bg2">
                                            <div>
                                                <a onClick={this.onClickSend} className="save_a">Send</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {step2 && (
                                <div className="clr_bg">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="edit__account">
                                                <p>Please input the verification number that was sent to your email address</p>
                                                <form action="#" method="POST" className="form">
                                                    <div className="form-group focused">
                                                        <label className="form-label" htmlFor="number">Verification Number</label>
                                                        <input onChange={this.onChangeVerificationNumber} id="number" value={verificationNumber}
                                                               className="form-input" type="text"/>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="refound_profile">
                                        <div className="row clr_bg2">
                                            <div>
                                                <a onClick={this.onClickVerify} className="save_a">Verify</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {step3 && (
                                <div className="clr_bg">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="edit__account">
                                                <p>Please input your new password</p>
                                                <form action="#" method="POST" className="form">
                                                    <div className="form-group focused">
                                                        <label className="form-label" htmlFor="password">Password</label>
                                                        <input onChange={this.onChangeNewPassword} id="password" value={newPassword}
                                                               className="form-input" type="password"/>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="refound_profile">
                                        <div className="row clr_bg2">
                                            <div>
                                                <a onClick={this.onClickReset} className="save_a">Save</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
        );
    };
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default compose(connect(mapStateToProps), withAlert())(ResetPasswordPage);
